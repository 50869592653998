// 账号列表-绑定api label 格式化
const accountFormat = function(web,web_type){
    // key
    // if(web_type==1||web_type==2){//dex、nft
    //     var isT1=1
    //     var label1='walletAddress'
    // }else{//cex
    //     var isT1=0
    //     var label1='API Key'
    // }

    // secret
    if(
        web=='ok'||
        web=='99ex'||
        web=='73jie'||
        web=='bibr'||
        web=='kucoin'||
        web=='fubt'||
        web=='exnow'||
        web=='coinmex'||
        web=='zt'||
        web=='winex_old'||
        web=='bh'||
        web=='utex'||
        web=='un'||
        web=='ocx_gl'||
        web=='thunderex'||
        web=='exnow'||
        web=='bitmart'||
        web=='bitget'||
        !web
    ){
        var isT2=0
        var label2='Secret Key'
    }else if(web_type==1||web_type==2){//dex、nft
        var isT2=1
        var label2='privateKey'
    }else{//cex
        var isT2=0
        var label2='Api Secret'
    }

    // 附加项 account_id
    var showLabel3=1
    if(
        web=='ok'||
        web=='99ex'||
        web=='73jie'||
        web=='bitget'||
        web=='luxevault'||
        web=='deepcoin'
    ){
        var isT3=0
        var label3='Passphrase'
    }else if(web=='bibr'){
        var isT3=0
        var label3='MerchantID'
    }else if(web=='kucoin'){
        var isT3=1
        var label3='apiPWD'
    }else if(web=='fubt'){
        var isT3=1
        var label3='payPWD'
    }else if(
        web=='exnow'||
        web=='coinmex'||
        web=='zt'||
        web=='winex_old'||
        web=='bh'||
        web=='utex'||
        web=='un'||
        web=='ocx_gl'||
        web=='thunderex'
    ){
        var isT3=0
        var label3='UID'
    }else if(web=='bitmart'){
        var isT3=0
        var label3='API MEMO'
    }else{
        showLabel3=0
    }

	return {
        // isT1,label1,
        isT2,label2,
        isT3,label3,showLabel3
    }
};
export default accountFormat