<template>
  <div class="step">
    <!-- 初始化合约 -->
    <template v-if="stepNum == 0">
      <div class="title">{{ $t("guide.iniContract") }}</div>
      <div class="sub">{{ $t("guide.contractPH") }}</div>
      <div class="contentBox">
        <el-form
          :model="apiData"
          :rules="rules"
          ref="contractForm"
          class="leftBox formBox"
        >
          <el-form-item prop="contract">
            <el-input
              :placeholder="$t('guide.contractPH')"
              v-model="apiData.contract"
              autocomplete="off"
              class="inp"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="decimals">
            <el-input
              :placeholder="$t('tools.decimals')"
              v-model="apiData.decimals"
              autocomplete="off"
              class="inp"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            prop="fee"
            v-if="exchangeData.web.includes('uniswapv3')"
          >
            <el-input
              :placeholder="$t('tools.fee')"
              v-model="apiData.fee"
              autocomplete="off"
              class="inp"
            >
            </el-input>
          </el-form-item>

          <div class="g_btn btn" @click="nextStep">{{ $t("common.next") }}</div>
        </el-form>

        <div class="rightBox">
          <div class="imgBox">
            <img src="@/assets/images/guide/contract.png" alt="" />
          </div>
          <div class="apiDetail">
            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_1")
              }}
            </p>
            <p>{{ $t("guide.ans2_1") }}</p>

            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_2")
              }}
            </p>
            <p>{{ $t("guide.ans2_2") }}</p>
          </div>
        </div>
      </div>
    </template>

    <!-- 设置钱包 -->
    <template v-if="stepNum == 1">
      <div class="title">{{ $t("guide.bindWallet") }}</div>
      <div class="sub">{{ $t("guide.bindWalletSub") }}</div>
      <div class="contentBox">
        <el-form
          :model="apiData"
          :rules="rules"
          ref="apiForm"
          class="leftBox formBox"
        >
          <el-form-item prop="key">
            <el-input
              :placeholder="$t('guide.walletAddressPH')"
              v-model="apiData.key"
              autocomplete="off"
              class="inp"
            >
              <div slot="prefix" class="el-input__icon">
                <img src="@/assets/images/guide/key.png" />
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="secret">
            <el-input
              :placeholder="$t('guide.privateKeyPH')"
              v-model="apiData.secret"
              autocomplete="off"
              class="inp"
            >
              <div slot="prefix" class="el-input__icon">
                <img src="@/assets/images/guide/key.png" />
              </div>
            </el-input>
          </el-form-item>
          <div class="g_btn btn" @click="nextStep">{{ $t("common.next") }}</div>
        </el-form>

        <div class="rightBox">
          <div class="imgBox">
            <img src="@/assets/images/guide/contract.png" alt="" />
          </div>
          <div class="apiDetail">
            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_3")
              }}
            </p>
            <p>{{ $t("guide.ans2_3") }}</p>

            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_4")
              }}
            </p>
            <p>{{ $t("guide.ans2_4") }}</p>

            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_5")
              }}
            </p>
            <p>{{ $t("guide.ans2_5") }}</p>
          </div>
        </div>
      </div>
    </template>

    <!-- 交易钱包 -->
    <template v-if="stepNum == 2">
      <div class="title">{{ $t("guide.distribution") }}</div>
      <div class="sub">{{ $t("guide.distributionSub") }}</div>
      <div class="contentBox">
        <el-form
          :model="walletData"
          :rules="rules2"
          ref="walletForm"
          class="leftBox formBox"
        >
          <div class="cardBox">
            <div class="cardSub">{{ $t("guide.req") }}1</div>
            <div class="cardReq">{{ $t("guide.subWalletNum") }}</div>
            <el-form-item prop="num">
              <el-input
                :placeholder="$t('guide.subWalletNumPH')"
                v-model="walletData.num"
                autocomplete="off"
                class="inp"
              >
              </el-input>
            </el-form-item>
          </div>
          <!-- <div class="cardBox">
            <div class="cardSub">{{ $t("guide.req") }}2</div>
            <div class="cardReq">{{ $t("guide.allotAuthorize") }}</div>
            <el-form-item prop="authorize">
              <el-radio-group v-model="walletData.authorize">
                <el-radio :label="1">{{ $t("common.yes") }}</el-radio>
                <el-radio :label="0">{{ $t("common.no") }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </div> -->
          <!--  v-if="walletData.authorize == 0" -->
          <div class="cardBox">
            <div class="cardSub">{{ $t("guide.accountBalance") }}</div>
            <div class="cardReq">
              {{ yenUnit1_up }}：{{ walletBalance[yenUnit1_up] || 0 }}
              <div class="whiteBox"></div>
              {{ yenUnit2_up }}：{{ walletBalance[yenUnit2_up] || 0 }}
            </div>
            <el-form-item prop="allocate">
              <div class="allocateRow">
                <span class="label">
                  {{ yenUnit1_up }}
                </span>
                <el-input
                  :placeholder="$t('guide.allocateNumPH')"
                  v-model="walletData.allocation1"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
                <span class="maxBtn">{{ $t("common.max") }}</span>
              </div>
              <div class="allocateRow">
                <span class="label">
                  {{ yenUnit2_up }}
                </span>
                <el-input
                  :placeholder="$t('guide.allocateNumPH')"
                  v-model="walletData.allocation2"
                  autocomplete="off"
                  class="inp"
                >
                </el-input>
                <span class="maxBtn">{{ $t("common.max") }}</span>
              </div>
            </el-form-item>
          </div>

          <div class="g_btn btn" @click="nextStep">{{ $t("common.next") }}</div>
        </el-form>

        <div class="rightBox">
          <div class="imgBox">
            <img src="@/assets/images/guide/contract.png" alt="" />
          </div>
          <div class="apiDetail">
            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_6")
              }}
            </p>
            <p>{{ $t("guide.ans2_6") }}</p>

            <p class="apiReq">
              <img src="@/assets/images/guide/tip.png" alt="" />{{
                $t("guide.req2_7")
              }}
            </p>
            <p>{{ $t("guide.ans2_7") }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
// api
import { mapGetters } from "vuex";
import { addToken, getDexBalance, allocation } from "@/api/guide";
export default {
  props: ["stepNum", "exchangeData"],
  computed: {
    ...mapGetters(["account"]),
    yenUnit1_up() {
      return this.exchangeData.yenUnit1.toUpperCase();
    },
    yenUnit2_up() {
      return this.exchangeData.yenUnit2.toUpperCase();
    },
  },
  data() {
    return {
      walletBalance: {},
      apiData: {
        contract: "",
        decimals: "",
        fee: "",

        key: "",
        secret: "",
      },
      rules: {
        contract: {
          required: true,
          message: this.$t("guide.contractPH"),
          trigger: "blur",
        },
        decimals: {
          required: true,
          message: this.$t("tools.decimals"),
          trigger: "blur",
        },
        fee: { required: true, message: this.$t("tools.fee"), trigger: "blur" },

        key: {
          required: true,
          message: this.$t("guide.walletAddressPH"),
          trigger: "blur",
        },
        secret: {
          required: true,
          message: this.$t("guide.privateKeyPH"),
          trigger: "blur",
        },
      },

      walletData: {
        num: "",
        // authorize: 1,
        allocation1: "",
        allocation2: "",
      },
      rules2: {
        num: {
          required: true,
          message: this.$t("guide.subWalletNumPH"),
          trigger: "blur",
        },
        // authorize: [
        //   {
        //     required: true,
        //     message: this.$t("guide.allotAuthorizePH"),
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  methods: {
    // 清空验证提示
    clearValidate1() {
      this.$refs.contractForm.clearValidate();
    },
    clearValidate2() {
      this.$refs.apiForm.clearValidate();
    },
    clearValidate3() {
      this.$refs.walletForm.clearValidate();
    },
    // 获取余额
    getBalance() {
      getDexBalance({
        web: this.exchangeData.web,
        account: this.account,
      }).then((res) => {
        this.walletBalance = res;
      });
    },
    async nextStep() {
      if (this.stepNum == 0) {
        //初始化合约
        this.$refs.contractForm.validate((valid) => {
          if (valid) {
            // 初始化合约请求-添加token
            addToken({
              web: this.exchangeData.web,
              account: this.account,
              address: this.apiData.contract,
              decimal_num: this.apiData.decimals,
              fee: this.exchangeData.web.includes("uniswapv3")
                ? this.apiData.fee
                : null,
            }).then((res) => {
              this.$emit("update:stepNum", 1);
            });
          } else {
            return false;
          }
        });
      } else if (this.stepNum == 1) {
        //设置钱包
        this.$refs.apiForm.validate((valid) => {
          if (valid) {
            this.$emit("nextStep", this.apiData);
          } else {
            return false;
          }
        });
      } else {
        //交易钱包
        this.$refs.walletForm.validate((valid) => {
          if (valid) {
            // if (this.walletData.authorize == 0) {
            var obj = {};
            obj[this.yenUnit1_up] = this.walletData.allocation1;
            obj[this.yenUnit2_up] = this.walletData.allocation2;
            // } else {
            //   var obj = [];
            // }
            // 创建交易钱包请求
            allocation({
              web: this.exchangeData.web,
              account: this.account,
              num: this.walletData.num,
              approve: obj,
              allocation_data: obj,
            }).then((res) => {
              this.$emit("nextStep");
            });
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import './step.styl';
@import './step2.styl';
</style>