<template>
  <div>
    <div class="noviceGuide">
      <!-- header -->
      <div class="guideHeader">
        <img src="@/assets/images/guide/logo.png" alt="light" />

        <div class="guideHeaderRight">
          <el-popover
            popper-class="popoverWhite"
            class="item"
            placement="bottom"
            width="100"
            trigger="click"
          >
            <div class="icon" slot="reference">
              <i class="iconfont icon-lang"></i>
            </div>
            <ul class="head_list">
              <li
                v-for="(item, key, index) in langList"
                :key="index"
                :class="{ active: lang == key }"
                @click="langChange(key)"
              >
                {{ item }}
              </li>
            </ul>
          </el-popover>
          <span class="skipTutorial" @click="skipTutorial">{{
            $t("guide.skipTutorial")
          }}</span>
        </div>
      </div>
      <div class="scrollBox">
        <div class="contentBox">
          <!-- steps -->
          <div class="stepsBox">
            <img
              class="backBtn"
              @click="prevStep"
              v-if="stepActive != -1"
              src="@/assets/images/guide/back.png"
              alt="back"
            />
            <el-steps :active="stepActive" align-center>
              <el-step :title="$t('guide.selectWeb')"></el-step>
              <el-step :title="$t('guide.bindAccount')"></el-step>
              <el-step :title="$t('guide.selectRobot')"></el-step>
            </el-steps>
          </div>

          <!-- content form -->
          <div class="contentForm">
            <step0
              v-if="stepActive == -1"
              @skipTutorial="skipTutorial"
              @nextStep="nextStep"
            ></step0>

            <keep-alive>
              <step1
                ref="step1"
                v-if="stepActive == 0"
                @nextStep="nextStep"
                @openExchangePop="openExchangePop"
                :stepNum.sync="stepNum1"
              ></step1>

              <step2
                ref="step2"
                v-if="stepActive == 1 && webType == 0"
                @nextStep="nextStep"
              ></step2>
              <step2dex
                ref="step2dex"
                v-if="stepActive == 1 && webType != 0"
                @nextStep="nextStep"
                :stepNum.sync="stepNum2"
                :exchangeData="exchangeData"
              ></step2dex>

              <step3
                ref="step3"
                v-if="stepActive == 2"
                @nextStep="nextStep"
                :exchangeData="exchangeData"
              ></step3>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>

    <!-- 交易所选择弹窗 -->
    <exchange-dialog
      :show.sync="popExchange"
      :web="defaultWeb"
      @select="handleSelect"
      :isGuideWhite="true"
    />

    <!-- 绑定api loading -->
    <div class="apiLoadingBox" v-if="apiLoading">
      <div class="loadingHeader">
        <div class="imgBox">
          <img
            class="loadingCenter"
            src="@/assets/images/guide/loadingCenter.png"
            alt=""
          />
          <img
            class="loadingRing"
            src="@/assets/images/guide/loadingRing.png"
            alt=""
          />
        </div>
        <div class="state">
          <!-- 检测失败/正在检测... -->
          {{
            stateBind1 == 0 || stateBind2 == 0
              ? $t("guide.checkErr")
              : $t("guide.checkIng")
          }}
        </div>
        <img
          v-if="stateBind1 == 0 || stateBind2 == 0"
          @click="closeApiPop"
          src="@/assets/images/guide/close.png"
          alt="x"
          class="close"
        />
      </div>
      <div class="loadingContent">
        <div class="row">
          <div class="rowLeft">
            <img src="@/assets/images/guide/key2.png" alt="" />
            {{
              $t("guide.checkBind", {
                name: webType == 0 ? "API Key" : $t("account.walletAddress"),
              })
            }}
          </div>
          <i class="el-icon-loading" v-if="stateBind1 == -1"></i>
          <img
            class="stateImg"
            v-else-if="stateBind1 == 1"
            src="@/assets/images/guide/suc.png"
            alt=""
          />
          <img
            class="stateImg"
            v-else-if="stateBind1 == 0"
            src="@/assets/images/guide/err.png"
            alt=""
          />
        </div>
        <div class="rowStateText">
          {{
            stateBind1 == -1
              ? $t("guide.checkIng")
              : stateBind1 == 0
              ? $t("guide.checkBindErr", {
                  name: webType == 0 ? "API Key" : $t("account.walletAddress"),
                })
              : $t("guide.checkSuc")
          }}
        </div>

        <div class="row">
          <div class="rowLeft">
            <img src="@/assets/images/guide/wallet.png" alt="" />
            检测用户绑定的资产是否真实
          </div>
          <template v-if="stateBind1 == 1">
            <i class="el-icon-loading" v-if="stateBind2 == -1"></i>
            <img
              class="stateImg"
              v-else-if="stateBind2 == 1"
              src="@/assets/images/guide/suc.png"
              alt=""
            />
            <img
              class="stateImg"
              v-else-if="stateBind2 == 0"
              src="@/assets/images/guide/err.png"
              alt=""
            />
          </template>
        </div>
        <div class="rowStateText">
          {{
            stateBind1 == -1
              ? "等待检测"
              : stateBind1 == 0
              ? "结束检测"
              : stateBind2 == -1
              ? "检测中…"
              : stateBind2 == 0
              ? "当前账户资产为0，请稍后给账户充值或检查资产是否划转到现货账户"
              : "检测成功"
          }}
        </div>

        <div class="row" v-if="stateBind1 == 1 && stateBind2 == 1">
          <div class="rowLeft">
            <img src="@/assets/images/guide/check.png" alt="" />
            绑定成功
          </div>
          <!-- <i class="el-icon-loading"></i> -->
          <!-- <img class="stateImg" src="@/assets/images/guide/suc.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import langList from "@/assets/js/langList";
import { mapGetters } from "vuex";
import step0 from "./stepContent/step0.vue";
import step1 from "./stepContent/step1.vue";
import step2 from "./stepContent/step2.vue";
import step2dex from "./stepContent/step2dex.vue";
import step3 from "./stepContent/step3.vue";

import ExchangeDialog from "@/components/exchange.vue";

// api
import { bindWebPair, bindKey } from "@/api/guide";
export default {
  components: {
    step0,
    step1,
    step2,
    step2dex,
    step3,

    ExchangeDialog,
  },
  computed: {
    ...mapGetters(["account", "lang"]),
  },
  data() {
    return {
      langList: langList,

      stepActive: -1,
      stepNum1: 0, //step1的小步骤 交易所、交易对
      stepNum2: 0, //step2的小步骤

      // data
      exchangeData: {},
      apiData: {},

      // 交易所弹窗
      popExchange: false,
      defaultWeb: "",
      webType: "",

      // 绑定api loading
      apiLoading: false,
      stateBind1: -1, //检测状态 -1检测中 0失败 1成功
      stateBind2: -1,
    };
  },
  methods: {
    langChange(value) {
      this.$i18n.locale = value;
      this.$store.dispatch("app/setLang", value);
    },
    // 跳过教程
    skipTutorial() {
      this.$store.dispatch("user/setNovice", "0");
    },

    // 下一步
    async nextStep(data) {
      if (this.stepActive == 0) {
        this.exchangeData = data;
        this.stepNum2 = 0;
        this.stepActive++;
        // 保存盘口信息
        await this.bindWebPair();

        await this.$nextTick();
        if (this.webType == 0) {
          this.$refs.step2?.init(this.exchangeData);
        }
      } else if (this.stepActive == 1) {
        if (this.stepNum2 == 2) {
          //交易钱包跳配置机器人
          this.stepActive++;
          return;
        }
        // 状态初始化
        this.stateBind1 = -1;
        this.stateBind2 = -1;

        this.apiLoading = true;
        this.apiData = data;

        // 开始检测
        // 绑定API请求
        try {
          var res = await bindKey({
            web: this.exchangeData.web,
            key: this.apiData.key,
            secret: this.apiData.secret,
            exchange_account_id: this.apiData.exchange_account_id || 0,
            account: this.account,
          });
          if (res == 1) {
            this.stateBind1 = 1;
            this.stateBind2 = 1;

            if (this.webType == 0) {
              //cex跳配置机器人
              this.stepActive++;
            } else {
              //dex跳交易钱包
              this.stepNum2 = 2;
              this.$refs.step2dex?.getBalance()
            }

            this.apiLoading = false;

            // 完成的话修改本地存储交易所交易对
            // setTradingPairInfo
          } else {
            this.stateBind1 = 0;
            this.stateBind2 = 0;
          }
        } catch (error) {
          this.stateBind1 = 0;
          this.stateBind2 = 0;
        }
        // await this.delay(1000);
        // this.stateBind1 = 1;
        // await this.delay(1000);
        // this.stateBind2 = 1;
      } else {
        this.stepActive++;
      }
    },
    // 模拟请求
    // delay(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // },
    // 选择交易所交易对
    async bindWebPair() {
      const loading = Loading.service({
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      var res = await bindWebPair({
        web: this.exchangeData.web,
        account: this.account,
        coin_token: this.exchangeData.yenUnit1,
        money_token: this.exchangeData.yenUnit2,
      });
      loading.close();
      return res;
    },

    // 上一步
    prevStep() {
      if (this.stepActive == 0 && this.stepNum1 == 1) {
        this.stepNum1 = 0;
      } else if (this.stepActive == 1 && this.stepNum2 == 1) {
        // dex stepNum-1
        this.$refs.step2dex?.clearValidate2();

        this.stepNum2 = 0;
      } else if (this.stepActive == 1 && this.stepNum2 == 2) {
        // dex stepNum-2
        this.$refs.step2dex?.clearValidate3();

        this.stepNum2 = 1;
      } else {
        // 清空验证提示
        if (this.stepActive == 1) {
          // cex
          this.$refs.step2?.clearValidate();
          // dex stepNum-0
          this.$refs.step2dex?.clearValidate1();
        }else if(this.stepActive == 2){
          // cex
          this.$refs.step3?.clearValidate1();
          // dex
          this.$refs.step3?.clearValidate2();
        }

        this.stepActive--;
      }
    },

    // 交易所
    openExchangePop(web) {
      this.defaultWeb = web;
      this.popExchange = true;
    },
    handleSelect(data) {
      this.webType = data.web_type;
      this.$refs.step1.handleSelect(data);

      this.popExchange = false;
    },

    closeApiPop() {
      this.apiLoading = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import './noviceGuide.styl';
</style>